import React, { useState } from 'react';
import {
  Modal,
  Button,
} from 'semantic-ui-react';

interface ContactUsFormModalProps {
  color?: string;
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
}

export const ContactUsFormModal: React.FC<ContactUsFormModalProps> = ({ color = "blue", size = "medium" }) => {
  const [state, setState] = useState({
    open: false,
  });

  const { open } = state;

  return (
    <div>
      <Button
        css={{
          boxShadow:
            '0px 0px 37px 0px rgba(0,0,0,0.5) !important',
          marginBottom: '1ex !important',
          marginTop: '1ex !important',
        }}
        color={color}
        size={size}
        onClick={() => setState({ open: true })}
      >
        Contact Us
      </Button>

      <Modal
        closeIcon
        dimmer="blurring"
        open={open}
        onClose={() => setState({ open: false })}
      >
        <Modal.Content style={{ height: "90vh", margin: 0, padding: "0px" }}>
          <iframe
            src="https://forms.monday.com/forms/embed/49066f87e8f7dc94cc027ff531da32e4?r=euc1"
            width="100%"
            height="100%"
            style={{ border: 0, boxShadow: "5px 5px 56px 0px rgba(255.0,0,0,0.25)" }}
          ></iframe>
        </Modal.Content>
      </Modal>
    </div >
  );
};
